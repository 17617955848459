import React from "react"
import styled from "styled-components"
import tinycolor from "tinycolor2"
import dayjs from "dayjs"
import { StaticQuery, graphql } from "gatsby"

import Button from "./Button"
import { DarkModeContext } from "./ThemeProvider"
import SmallCaps from "./typography/SmallCaps"
import LayoutContainer from "./LayoutContainer"

import {
  TwitterIcon,
  GithubIcon,
  InstagramIcon,
  DribbbleIcon,
  MediumIcon,
  LinkedInIcon,
  YouTubeIcon,
  SoundCloudIcon,
} from "./icons"

const SocialLinksContainer = styled.div`
  margin-top: 1rem;
  > a {
    padding-right: 0.75rem;
  }
  grid-column: 1;
  filter: invert(${props => props.theme.INVERT});
`

const ButtonContainer = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  display: flex;
  align-items: center;
  justify-content: end;
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row: 3;
    margin-top: 1rem;
    align-items: center;
    justify-content: stretch;
  }
`

const FooterContainer = styled.footer`
  display: grid;
  grid-gap: 10px;
  /* grid-template-columns: 100px 100px 100px; */
  color: ${({ theme }) =>
    tinycolor(theme.colors.text)
      .setAlpha(0.5)
      .toHexString()};

  margin-bottom: ${({ theme }) => theme.space[5]};
  margin-top: ${({ theme }) => theme.space[5]};
`

const DarkModeButton = styled.button`
  margin-left: 0.5rem;
  min-width: 4rem;
  background: ${({ theme }) => theme.colors.headerBackground};
  border: ${({ theme }) =>
    tinycolor(theme.colors.headerBackground)
      .darken(10)
      .toHexString() + " 1px solid"};
  color: ${({ theme }) => theme.colors.text};
  border-radius: 4px;
  padding: 0.5em 1em;
  /* text-transform: uppercase;
  letter-spacing: 0.1em; */
  font-weight: 500;
  font-size: 0.85em;
  transition: all 0.2s;
  :hover {
    cursor: pointer;
    background-color: ${({ theme }) =>
      tinycolor(theme.colors.headerBackground)
        .darken(5)
        .toHexString()};
    border: ${({ theme }) =>
      tinycolor(theme.colors.headerBackground)
        .darken(20)
        .toHexString() + " 1px solid"};
  }
`

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

const SocialLinks = () => (
  <>
    <a
      href="https://twitter.com/lucasmcgartland"
      target="_blank"
      rel="noopener noreferrer"
    >
      <TwitterIcon />
    </a>
    <a
      href="https://github.com/lmcgartland"
      target="_blank"
      rel="noopener noreferrer"
    >
      <GithubIcon />
    </a>
    <a
      href="https://instagram.com/lucasmcgartland"
      target="_blank"
      rel="noopener noreferrer"
    >
      <InstagramIcon />
    </a>
    <a
      href="https://dribbble.com/lucasmcgartland"
      target="_blank"
      rel="noopener noreferrer"
    >
      <DribbbleIcon />
    </a>
    <a
      href="https://medium.com/@lucasmcgartland"
      target="_blank"
      rel="noopener noreferrer"
    >
      <MediumIcon />
    </a>
    <a
      href="https://www.linkedin.com/in/lucasmcgartland/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <LinkedInIcon />
    </a>
    <a
      href="https://www.youtube.com/channel/UCNDNGITfS5-2Vs9ezR_uhrg"
      target="_blank"
      rel="noopener noreferrer"
    >
      <YouTubeIcon />
    </a>
    <a
      href="https://soundcloud.com/lucasmcgartland"
      target="_blank"
      rel="noopener noreferrer"
    >
      <SoundCloudIcon />
    </a>
  </>
)

const Footer = ({ buildTime }) => (
  <LayoutContainer hasPaddingTop={false} hasPaddingBottom={false}>
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
            buildTime
          }
        }
      `}
      render={data => (
        <FooterContainer>
          <SmallCaps>
            Made in Oceanside, CA • Last Updated:{" "}
            {MONTHS[dayjs(data.buildTime).get("month")]}{" "}
            {dayjs(data.buildTime).format("YYYY")}
          </SmallCaps>
          <SocialLinksContainer>
            <SocialLinks />
          </SocialLinksContainer>
          <ButtonContainer>
            <Button
              onClick={() => {
                window.location.href =
                  "mailto:lucasmcgartland@gmail.com?subject=Hi"
              }}
            >
              Say hello
            </Button>
            <DarkModeContext.Consumer>
              {({ darkModeOn, setDarkMode }) => (
                <DarkModeButton
                  onClick={() => {
                    setDarkMode(!darkModeOn)
                  }}
                >
                  {darkModeOn ? "Light" : "Dark"}
                </DarkModeButton>
              )}
            </DarkModeContext.Consumer>
          </ButtonContainer>
        </FooterContainer>
      )}
    />
  </LayoutContainer>
)

export default Footer
