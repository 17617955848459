import styled from "styled-components"
import tinycolor from "tinycolor2"

const CardContainer = styled.div`
  background-color: ${({ theme }) =>
    tinycolor(theme.colors.cardBackground)
      .darken(1)
      .toHexString()};
  cursor: pointer;
  transition: all 0.2s;
  padding: ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii[3]};
  color: ${({ theme }) =>
    tinycolor(theme.colors.cardText)
      .darken(20)
      .toHexString()};
  border-width: ${({ theme }) => theme.borderWidths[1]};
  border-style: solid;
  border-color: ${({ theme }) =>
    tinycolor(theme.colors.cardBackground)
      .darken(5)
      .toHexString()};

  &:hover {
    color: #000;
    background-color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) =>
      tinycolor(theme.colors.primary)
        .darken(10)
        .desaturate(30)
        .toHexString()};
    /*transform: skewY(-5deg);*/

    transform: scale(1.05);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  }
`

export default CardContainer
