import tinycolor from "tinycolor2"
import styled from "styled-components"

const Button = styled.button`
  flex: 1;
  background: ${({ theme }) => theme.colors.primary};
  border-style: solid;
  border-width: 1px;
  border-color: ${({ theme }) =>
    tinycolor(theme.colors.primary)
      .darken(10)
      .desaturate(30)
      .toHexString()};
  color: ${({ theme }) =>
    tinycolor(theme.colors.primary)
      .darken(55)
      .toHexString()};
  border-radius: ${({ theme }) => theme.radii[2]};
  padding: 0.5em 1em;
  font-weight: 500;
  font-size: 0.85em;
  transition: all 0.2s;

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) =>
      tinycolor(theme.colors.primary)
        .darken(5)
        .desaturate(15)
        .toHexString()};
    border: ${({ theme }) =>
      tinycolor(theme.colors.primary)
        .darken(20)
        .desaturate(30)
        .toHexString() + " 1px solid"};
  }
`

export default Button
