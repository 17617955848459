import styled from "styled-components"
import { space } from "styled-system"

const Divider = styled.hr(
  ({ theme }) => ({
    background: theme.colors.divider,
    marginBottom: theme.space[3],
  }),
  space
)

export default Divider
