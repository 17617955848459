import styled from "styled-components"

const HighlightedLink = styled.a`
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;
  &:hover {
    background: ${({ theme }) => theme.colors.primary};
  }
`
export default HighlightedLink
