import styled from "styled-components"

const ProjectContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  /* grid-template-columns: 32% 32% 32%; */
  /* grid-gap: 2%; */
  row-gap: 1em;
  column-gap: 1em;
  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`
export default ProjectContainer
