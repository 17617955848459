import React from "react"
import styled from "styled-components"

import Divider from "./Divider"
import SectionTitle from "./SectionTitle"

const SectionContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.space[6]};
`

const Section = ({ title, children }) => {
  return (
    <SectionContainer>
      <SectionTitle>{title}</SectionTitle>
      <Divider />
      {children}
    </SectionContainer>
  )
}

export default Section
