import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.colors.background};
    color:${({ theme }) => theme.colors.text};
    transition: color .2s, background .2s;

    // @media (prefers-color-scheme: dark) {
    //   background: red !important;
    // }
    // @media (prefers-color-scheme: light) {
    //   background: blue !important;
    // }
  }
`
export default GlobalStyle
