import styled from "styled-components"
import HighlightedLink from "./HighlightedLink"

const HighlightedLinkStrong = styled(HighlightedLink)`
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
`

HighlightedLinkStrong.defaultProps = {
  rel: "noopener noreferrer",
  href: "https://www.viasat.com",
}

export default HighlightedLinkStrong
