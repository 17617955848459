import React from "react"
import slugify from "slugify"
import styled from "styled-components"

const scrollOffset = 100
const anchorLinkStyle = {
  marginTop: -1 * scrollOffset,
  paddingTop: scrollOffset,
}

const P = styled.p`
  font-family: Georgia;
`

const A = styled.a`
  color: ${props => props.theme.TEXT_COLOR};
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    background: ${props => props.theme.ACCENT_COLOR};
  }
`

const components = {
  // p: P,
  li: props => <li style={{ margin: 0, padding: 0 }} {...props} />,
  ul: props => <ul style={{ marginTop: 0 }} {...props} />,
  a: props => <A {...props} />,
  h1: props => (
    <h1
      style={anchorLinkStyle}
      id={slugify(props.children, {
        replacement: "-", // replace spaces with replacement
        remove: null, // regex to remove characters
        lower: true, // result in lower case
      })}
      {...props}
    />
  ),
  h2: props => (
    <h2
      style={anchorLinkStyle}
      id={slugify(props.children, {
        replacement: "-", // replace spaces with replacement
        remove: null, // regex to remove characters
        lower: true, // result in lower case
      })}
      {...props}
    />
  ),
  h3: props => (
    <h3
      style={anchorLinkStyle}
      id={slugify(props.children, {
        replacement: "-", // replace spaces with replacement
        remove: null, // regex to remove characters
        lower: true, // result in lower case
      })}
      {...props}
    />
  ),
}

export default components
