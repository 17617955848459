import styled from "styled-components"

const SmallCaps = styled.div`
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.8em;
  font-weight: 500;
  opacity: 0.4;
  grid-column: 1;
  grid-row: 1;
  /* background: blue; */
`
export default SmallCaps
