import React from "react"
import LazyLoad from "react-lazyload"
import Img from "gatsby-image"

import CardTitle from "./CardTitle"
import SmallText from "./typography/SmallText"
import Link from "./Link"
import ProjectCardContainer from "./ProjectCardContainer"
import ExternalLinkArrow from "./ExternalLinkArrow"

const ProjectCard = ({
  title,
  subtitle,
  link,
  external,
  imageSrcFluid,
  publishedDate,
}) => (
  <Link style={{ textDecoration: "none" }} to={link !== "" ? link : null}>
    <ProjectCardContainer disabled={link !== ""}>
      <LazyLoad once>
        <Img
          className="ProjectCardContainerImg" // See ProjectCardContainer for style
          fluid={imageSrcFluid}
        />
      </LazyLoad>
      <div>
        <CardTitle project>{title}</CardTitle>
        <SmallText>{subtitle}</SmallText>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <SmallText>{publishedDate}</SmallText>
          {link !== "" && <ExternalLinkArrow />}
        </div>
      </div>
    </ProjectCardContainer>
  </Link>
)

export default ProjectCard
