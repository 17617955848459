import React from "react"
import styled from "styled-components"

const StyledPath = styled.path`
  fill: ${({ theme }) => theme.colors.text};
`
const LukeLogo3 = () => {
  return (
    <svg
      style={{ marginTop: 8 }}
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <StyledPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 3.3L7 0.266663H10.5L8.75 3.3L7 6.33333L5.25 9.36667L3.5 12.4L5.25 15.4333H1.75L0 12.4L1.75 9.36667L3.5 6.33333L5.25 3.3ZM12.25 3.3L10.5 6.33333L8.75 9.36667L7 12.4H10.5L12.25 9.36667L14 12.4L15.75 9.36667L12.25 9.36667L14 6.33333L12.25 3.3ZM14 6.33333L15.75 3.3L17.5 6.33333L19.25 9.36667L21 12.4H17.5L15.75 9.36667L14 6.33333ZM3.5 18.4667L1.75 15.4333H5.25H8.75H12.25H15.75H15.75H19.25L17.5 18.4667H14H10.5H7H3.5Z"
      />
    </svg>
  )
}

export default LukeLogo3
