import styled from "styled-components"
import { space, color, layout, border } from "styled-system"

const Box = styled.div(
  {
    boxSizing: "border-box",
    minWidth: 0,
  },
  space,
  color,
  layout,
  border
)

export default Box
