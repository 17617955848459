import React from "react"
import LazyLoad from "react-lazyload"
import styled from "styled-components"
import tinycolor from "tinycolor2"

import CardTitle from "./CardTitle"
import SmallText from "./typography/SmallText"
import CardContainer from "./CardContainer"
import Link from "./Link"

const PreviewImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
  border-radius: 4px;
  min-height: ${({ theme }) => theme.sizes[3]};
  margin-bottom: 0px;
`

const PreviewImageLoadingContainer = styled.div`
  @keyframes gradientBG {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @-webkit-keyframes gradientBG {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  width: 100%;
  border-radius: 4px;
  min-height: ${({ theme }) => theme.sizes[3]};

  background: linear-gradient(
    -45deg,
    ${({ theme }) =>
      tinycolor(theme.colors.cardBackground)
        .darken(10)
        .toHexString()},
    ${({ theme }) =>
      tinycolor(theme.colors.cardBackground)
        .darken(30)
        .toHexString()},
    ${({ theme }) =>
      tinycolor(theme.colors.cardBackground)
        .darken(20)
        .toHexString()}
  );
  background-size: 400% 400%;
  -webkit-animation: gradientBG 2s ease infinite;
  animation: gradientBG 10s ease infinite;

  margin-bottom: 10px;
`

const Card = ({ external, href, imageSrc, title, publishedDate }) => {
  return (
    <Link
      target={external ? "_blank" : null}
      rel={external ? "noopener noreferrer" : null}
      style={{ textDecoration: "none" }}
      href={href}
    >
      <CardContainer>
        <LazyLoad once>
          <PreviewImageLoadingContainer>
            <PreviewImage src={imageSrc} />
          </PreviewImageLoadingContainer>
        </LazyLoad>
        <CardTitle>{title}</CardTitle>
        <SmallText>{publishedDate}</SmallText>
      </CardContainer>
    </Link>
  )
}

Card.defaultProps = {
  external: false,
}
export default Card
