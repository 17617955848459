import styled from "styled-components"

import CardContainer from "./CardContainer"

const ProjectCardContainer = styled(CardContainer)`
  opacity: ${props => !props.disabled && 0.35};
  cursor: ${props => !props.disabled && "not-allowed"};

  .ProjectCardContainerImg {
    margin-bottom: 10px;
    border-radius: 8px;
  }
  @media (max-width: 800px) {
    display: flex;
    flex-direction: row;
    .ProjectCardContainerImg {
      width: ${({ theme }) => theme.sizes[4]};
      display: block;
      margin: 0px;
      padding: 0px;
      margin-right: ${({ theme }) => theme.space[3]};
    }
    div {
      flex: 1;
    }
  }
`

ProjectCardContainer.defaultProps = {
  disabled: false,
}

export default ProjectCardContainer
