import React from "react"

const ExternalLinkArrow = () => {
  return (
    <svg
      style={{
        marginTop: "0.6em",
        width: "auto",
      }}
      height="0.6em"
      viewBox="0 0 68 68"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Tiles"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g
          id="Artboard"
          transform="translate(-36.000000, -36.000000)"
          stroke="#979797"
          strokeWidth="8"
        >
          <g id="Group" transform="translate(40.000000, 39.500000)">
            <path d="M0,60.5 L60,0.5" id="Path-2" />
            <path d="M20,0.5 L60,0.5" id="Path-3" />
            <path d="M60,40.5 L60,0.5" id="Path-4" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ExternalLinkArrow
