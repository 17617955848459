import styled, { css } from "styled-components"

const CardTitle = styled.div`
  font-size: 0.8em;
  font-weight: 400;
  line-height: 1.5em;
  @media (max-width: 800px) {
    font-size: 0.9em;
  }
  @media (max-width: 500px) {
    font-size: 1em;
  }
  ${props =>
    props.project &&
    css`
      text-transform: uppercase;
      letter-spacing: 3px;
      font-weight: 500;
    `}
`
export default CardTitle
