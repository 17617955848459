import styled from "styled-components"
import { space } from "styled-system"

const LayoutContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${props => props.maxWidth || "920px"};
  padding: 0px ${({ theme }) => theme.space[3]} 0px;
  padding-top: ${props => props.hasPaddingTop && props.theme.space[6]};
  padding-bottom: ${props => props.hasPaddingBottom && props.theme.space[6]};

  @media (max-width: ) {
    padding-top: ${({ theme }) => theme.breakpoints[3]};
  }
  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding-top: ${props => props.hasPaddingTop && props.theme.space[5]};
  }
  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    padding-top: ${props => props.hasPaddingTop && props.theme.space[4]};
  }
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
    padding-top: ${props => props.hasPaddingTop && props.theme.space[3]};
  }
`

LayoutContainer.defaultProps = {
  hasPaddingTop: true,
  hasPaddingBottom: false,
  maxWidth: "920px",
}

const SystemedLayoutContainer = styled(LayoutContainer)(space)

export default SystemedLayoutContainer
