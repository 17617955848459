import React from "react"
import styled from "styled-components"

import Divider from "./Divider"
import LayoutContainer from "./LayoutContainer"
import Link from "./Link"

const NavLinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: ${({ theme }) => theme.space[4]};
  a {
    color: ${({ theme }) => theme.colors.text} !important;
  }
`

const BrowseLinksSection = ({ previous, next }) => (
  <>
    <Divider mb={0} />
    <LayoutContainer p={0}>
      <NavLinksContainer>
        <div>
          {previous && (
            <Link to={previous.fields.slug} rel="prev">
              ← {previous.frontmatter.title}
            </Link>
          )}
        </div>
        <div>
          {next && (
            <Link to={next.fields.slug} rel="next">
              {next.frontmatter.title} →
            </Link>
          )}
        </div>
      </NavLinksContainer>
    </LayoutContainer>
    <Divider />
  </>
)

export default BrowseLinksSection
